import React, { useState, useEffect } from "react";
import { Card, Form, Input, Button, Row, Col, Select, Spin, message, Checkbox, Upload, Skeleton } from "antd";
import { useSelector, useDispatch } from 'react-redux'
import { TagFilled, DownloadOutlined, CalendarFilled, DownOutlined, UpOutlined, CreditCardFilled, CloudUploadOutlined,MobileOutlined , BankOutlined, ExpandOutlined ,TeamOutlined, StarFilled, FileDoneOutlined, CheckCircleOutlined, MailFilled, QuestionCircleOutlined } from '@ant-design/icons';
import { Layout, Radio } from "antd";
import { Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { useLocation, Link } from "react-router-dom";

import axios from '../util/Api';

import '../styles/kernel.scss'
import '../styles/course.scss'
import { setResponsive } from "../appRedux/actions/Responsive";
import { getUser } from "../appRedux/actions/Auth";
import NavBar from "../components/NavBarPublic";


import yapeImg from '../assets/images/yape.jpeg';
import logoNiubiz from '../assets/images/logo-niubiz.png';
import logosCards from '../assets/images/BANNER CABECERA.png';


import checkedSVG from '../assets/svg/checked.svg';
const { Panel } = Collapse;

const { Content, Footer } = Layout;
var elementStyles = {
    base: {
        color: '#666666',
        fontWeight: 400,
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        padding: '10px',
        placeholder: {
            color: '#999999'
        },
        autofill: {
            color: '#e39f48'
        }
    },
    invalid: {
        color: '#E25950',
        '::placeholder': {
            color: '#FFCCA5'
        }
    }
};

const Inscription = () => {
    const query = new URLSearchParams(useLocation().search)
    const id = query.get('id')
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const responsive = useSelector(store => store.responsive.value)
    const authUser = useSelector(store => store.auth.authUser)

    const [checkTerms, setCheckTerms] = useState(false)

    const [namePerson, setNamePerson] = useState('')
    const [lastName1, setLastName1] = useState('')
    const [lastName2, setLastName2] = useState('')
    const [typeDoc, setTypeDoc] = useState('')
    const [nroDoc, setNroDoc] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')

    const [typePayment, setTypePayment] = useState('')
    const [voucher, setVoucher] = useState('')
    const [voucherType, setVoucherType] = useState('TICKET')
    const [invoiceRuc, setInvoiceRuc] = useState('')
    const [invoiceBusinessName, setInvoiceBusinessName] = useState('')
    const [saveSuccess, setSaveSuccess] = useState(0)
    const [saveSuccessData, setSaveSuccessData] = useState({})
    const [programmingData, setProgrammingData] = useState({})

    let RegexEmail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,6})+$/;
    let RegexNumber = /^([0-9])*$/;

    const [session, setSession] = useState([])
    const [activePayment, setActivePayment] = useState(false)
    const [loading, setLoading] = useState(false)

    const [nameNiubiz, setNameNiubiz] = useState('')
    const [lnameNiubiz, setLNameNiubiz] = useState('')
    const [emailNiubiz, setEmailNiubiz] = useState('')

    const [courses, setCourses] = useState([])
    const [coursesSeguriton, setCoursesSeguriton] = useState([])
    const [rulesSeguriton, setRulesSeguriton] = useState([])
    const [total, setTotal] = useState(0)
    const [discount, setDiscount] = useState('0.00')

    const [showForm, setShowForm] = useState(false)
    const [wayToPay, setWayToPay] = useState('')
    const [loadingCourse, setLoadingCourse] = useState(false)
    const [errorNiubiz, setErrorNiubiz] = useState(false)






    useEffect(() => {


        dispatch(setResponsive())
        dispatch(getUser())
        setTypePayment('')

        getCourse()



    }, [])

    const getSession = () => {

        axios.post('/external/niubiz/session', { amount: total }
        ).then(({ data }) => {
            setSession(data)
            // setActivePayment(true)
            setLoadingCourse(false)

        }).catch(function (error) {
            setLoadingCourse(false)

        })

    }




    const createForm = () => {
        window.cardNumber = window.payform.createElement('card-number', {
            style: elementStyles,
            placeholder: 'N° de Tarjeta'
        }, 'txtNumeroTarjeta');
        window.cardExpiry = window.payform.createElement('card-expiry', {
            style: elementStyles,
            placeholder: 'MM/AA'
        }, 'txtFechaVencimiento');
        window.cardCvv = window.payform.createElement('card-cvc', {
            style: elementStyles,
            placeholder: 'CVV'
        }, 'txtCvv');
        setShowForm(true)

    }

    const setConfigurationScriptLoaded = () => {


        var configuration = {
            sessionkey: session.sesionKey,
            channel: "web",
            merchantid: session.merchantId,
            purchasenumber: session.purchaseNumber,
            amount: session.amount,
            language: "es", // esto falta en su documentación de nibubiz
            merchantConfiguration: {
                recurrencyEnabled: false,
                tokenizationEnabled: false,
            },
            font: "https://fonts.googleapis.com/css?family=Montserrat:400&display=swap"
        };
        window.configuration = configuration
        window.payform.setConfiguration(configuration)
        createForm()
    }

    const addCustomPayScript = () => {
        return new Promise((resolve) => {
            if (window.payform) {
                resolve()
            } else {
                setShowForm(false)

                const script = document.createElement('script')
                script.id = 'niubiz_script'
                script.src = process.env.REACT_APP_NIUBIZ_JS
                script.async = true
                script.onload = () => setConfigurationScriptLoaded()
                document.head.appendChild(script)
                resolve()
            }

        })
    }

    const importFiles = async () => {
        try {
            await addCustomPayScript()
        } catch (error) {
            message.error(error)
            console.log('Error al cargar el script de pago', error)
        }
    }


    // useEffect(() => {
    //     if (activePayment) {
    //         importFiles()
    //     }
    // }, [activePayment])


    // useEffect(() => {
    //     if (total > 0) {

    //     getSession()
    //     }
    // }, [total])



    useEffect(() => {
        if (session.sesionKey != undefined) {
            resetPayment()

        }


    }, [session])


    useEffect(() => {

    }, [courses, coursesSeguriton, total])


    const payVisa = () => {

        if (!checkTerms) {
            message.warning('Debe aceptar los términos y condiciones')
            return false;
        }


        if (nameNiubiz == '') {
            message.warning('Ingrese un nombre en el formulario NIUBIZ')
            return false;
        }
        if (lnameNiubiz == '') {
            message.warning('Ingrese sus apellidos en el formulario NIUBIZ')
            return false;
        }
        if (emailNiubiz == '') {
            message.warning('Ingrese un correo en el formulario NIUBIZ')
            return false;
        }

        if (!RegexEmail.test(emailNiubiz)) {
            message.warning('El correo ingresado en el formulario NIUBIZ no tiene el formato correcto')
            return false;


        }




        setLoading(true)
        var data = {
            name: nameNiubiz,
            lastName: lnameNiubiz,
            email: emailNiubiz,
            phoneNumber: '',
            currencyConversion: false,
            recurrence: false,
            alias: nameNiubiz
        }
        window.payform.createToken(
            [window.cardNumber, window.cardExpiry, window.cardCvv],
            data
        )
            .then(function (res) {



                save(res.transactionToken)
            })
            .catch((error) => {
                message.error(error)
                setLoading(false)
                getSession()


            });

    }

    const resetPayment = () => {

        let myPromise = new Promise(function (myResolve, myReject) {
            if (document.getElementById("niubiz_script")) {
                document.getElementById("niubiz_script").remove()

            }
            console.log('reset')
            //  document.head.getElementsByTagName("script")[0].remove()
            //  document.head.getElementsByTagName("script")[1].remove()


            setShowForm(false)

            document.getElementById('txtNumeroTarjeta').innerHTML = ''
            document.getElementById('txtFechaVencimiento').innerHTML = ''
            document.getElementById('txtCvv').innerHTML = ''


            myResolve();
            myReject();
        });

        myPromise.then(
            function (value) {
                console.log('reset')
                const script = document.createElement('script')
                script.id = 'niubiz_script'
                script.src = process.env.REACT_APP_NIUBIZ_JS
                script.async = true
                script.onload = () => setConfigurationScriptLoaded()
                document.head.appendChild(script)
            },
            function (error) { }
        );
    }



    // const onPayRecurrency = async () => {
    //     const json = {
    //         "antifraud": null,
    //         "captureType": "manual",
    //         "channel": "recurrent",
    //         "countable": true,
    //         "order": {
    //             "amount": 100,
    //             "currency": "PEN",
    //             "purchaseNumber": PUCHASE_NUMBER,
    //         },
    //         "card": {
    //             "tokenId": 7000010038732941,
    //         },
    //         "cardHolder": {
    //             "email": "berzavlu@gmail.com",
    //         },
    //     }
    //     try {
    //         const restoken = await await getTokenVisa()
    //         console.log(restoken.res)
    //         const res = await sendTransactionRecurrent(MERCHANT_ID, restoken.res, json)
    //         console.log(res)
    //     } catch (error) {
    //         alert('error')
    //         console.log('Ocurrió un error al realizar la transacción', error)
    //     }
    // }

    const getCourse = () => {
        axios.get('/external/programming/' + id, {
            headers: {
                "X-Requested-With": "XMLHttpRequest"
            }
        }
        ).then(({ data }) => {

            if (data.seguriton) {

                setCoursesSeguriton(data.courses)
                setRulesSeguriton(data.rules)
                setProgrammingData([{ participantPrice: '1', }])
                // setName('curso')
                // setPriceNew('2')39

            } else {
                let programming = data.programming
                // setProgrammingData(programming)
                // setName(programming.course.name)
                // setPriceNew(programming.participantPrice)
                setCourses([{
                    idProgramming: programming.idProgramming, course_name: programming.course.name, price: programming.participantPrice,
                    modality: programming.modality
                }])


                setTotal(programming.participantPrice)

                // document.getElementById('wire-info').innerHTML = programming.waytopay
                setWayToPay(programming.waytopay)

            }




        }).catch(function (error) {
            console.log("Error****:", error)
        })

    }
    const validate = () => {
        let $msg = ''


        if (courses.length == 0) {
            $msg = 'Seleccione al menos un curso'

        }

        if (programmingData.participantPrice !== "0.00" && programmingData.participantPrice !== "GRATUITO") {
            if (typePayment != 'NIUBIZ') {
                if (voucher == '') {
                    $msg = 'Adjunte el voucher'
                }
            }

            if (voucherType == '') {
                $msg = 'Seleccione un tipo de comprobante'
            } else {
                if (voucherType == 'INVOICE') {
                    if (invoiceBusinessName == '') {
                        $msg = 'Ingrese razón social'
                    }
                    if (invoiceRuc == '') {
                        $msg = 'Ingrese un RUC'
                    }
                }
            }
        }

        if (!authUser) {
            if (email == '') {
                $msg = 'Ingrese un correo'
            }
            if (!RegexEmail.test(email)) {
                $msg = "La dirección de email es incorrecta."
            }

            if (lastName2 == '') {
                $msg = 'Ingrese un apellido materno'
            }

            if (lastName1 == '') {
                $msg = 'Ingrese un apellido paterno'
            }

            if (namePerson == '') {
                $msg = 'Ingrese un nombre'
            }

            if (typeDoc == "DNI") {
                if (nroDoc.length != 8) {
                    $msg = 'El número de documento debe tener 8 caracteres'
                }

                if (!RegexNumber.test(nroDoc)) {
                    $msg = "El número de documento solo debe contener números."
                }
            } else {
                if (nroDoc.length < 5 && nroDoc.length > 12) {
                    $msg = 'El número de documento debe tener entre 5 y 12 caracteres'
                }
            }

            if (typeDoc == '') {
                $msg = 'Seleccione un tipo de documento'
            }
        }

        if (typePayment == '') {
            $msg = 'Seleccione un método de pago'

        }


        if (!checkTerms) {
            $msg = 'Debe aceptar los términos y condiciones'

        }



        if ($msg != '') { message.warning($msg) }
        return $msg
    }

    function handleChangeTypeDocument(event) {
        setNroDoc('')
        setTypeDoc(event)
    }

    function handleChangeClientDocument(event) {
        setNroDoc(event.target.value.replace(/\D/, ''))
    }

    const save = (token = '') => {
        setErrorNiubiz(false)

        let url = ''
        if (!authUser) {
            url = '/external/inscription/processVirtualInscription'
        } else {
            url = '/inscription/processVirtualInscription'

        }
        if (validate() == '') {
            let request = {}
            if (typePayment == 'NIUBIZ') {
                request = {
                    typeDoc: typeDoc,
                    nroDoc: nroDoc,
                    name: namePerson,
                    lastName1: lastName1,
                    lastName2: lastName2,
                    email: email,
                    paymentType: typePayment,
                    voucher_type: voucherType,
                    invoice_ruc: invoiceRuc,
                    invoice_businessName: invoiceBusinessName,
                    participant_voucher: voucher.name,
                    phone: phone,
                    token: session.token, amount: total, transactionToken: token, purchase: session.purchaseNumber,
                    programmings: courses

                }
            } else {

                request = {
                    typeDoc: typeDoc,
                    nroDoc: nroDoc,
                    name: namePerson,
                    lastName1: lastName1,
                    lastName2: lastName2,
                    email: email,
                    paymentType: typePayment,
                    voucher_type: voucherType,
                    invoice_ruc: invoiceRuc,
                    invoice_businessName: invoiceBusinessName,
                    participant_voucher: voucher.name,
                    phone: phone,
                    programmings: courses

                }

            }

            axios.post(url, request
            ).then(({ data }) => {
                message.success(data.message)
                setSaveSuccess(1)
                setSaveSuccessData(data)
                setLoading(false)
                getSession()

            }).catch(function (error) {

                if (typePayment != 'NIUBIZ') {

                    if (error.response) {
                        message.error(error.response.data.message)

                    } else {
                        message.error(error.message)

                    }
                } else {


                    setErrorNiubiz(error.response.data)
                }


                console.log("Error****:", error.message)
                setLoading(false)
                getSession()


            })
        } else {
            getSession()

        }



    }

    const onChangePaymentMethod = e => {
        if (e == 'NIUBIZ') {
            getSession()
        }
        setVoucher('')
        setTypePayment(e)



    }

    const props_upload = {
        name: "file_to_upload",
        action: `${process.env.REACT_APP_API}/external/files/storage`,

        fileList: [],

        onChange(info) {
            console.log(info)

            if (info.file.status !== "uploading") {

            }
            if (info.file.status == 'done') {
                setVoucher(info.file.response)

                message.success(`${info.file.name} archivo subido correctamente`)


            } else if (info.file.status === "error") {
                message.error(`${info.file.name} hubo un error al subir el archivo.`)
            }


        },
        onRemove: file => {
            setVoucher()
        },
    }


    const uploadFile = (e) => {
        if(e.target.files[0]){
            let formData = new FormData();
            formData.append("file_to_upload", e.target.files[0]);
            axios.post(`${process.env.REACT_APP_API}/external/files/storage`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            },
            ).then(({ data }) => {
                if (data.status == 'done') {
                    setVoucher(data)
                    message.success(`Archivo subido correctamente`)
    
                }
    
            }).catch(function (error) {
    
                message.error(`Hubo un error al subir el archivo.`)
    
    
            })
        }
        }
       

    function beforeUpload(file) {

        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf'
        if (!isJpgOrPng) {
            message.error('Solo se permiten imagenes o archivos pdf.')
        }
        const isLt2M = file.size / 1024 / 1024 < 2
        if (!isLt2M) {
            message.error('Tmaño máximo de archivo: 2MB!')
        }
        return isJpgOrPng && isLt2M
    }

    const uploadButton = (
        <div style={{ marginTop: 8, width: '100px !important' }}>
            <label className='label-input-file' for='input-file'>
            <div style={{ marginTop: 8, width: '140px' }} >
                <CloudUploadOutlined /> Subir Voucher
            </div>
            </label>
            <input id='input-file' className='input-file' placeholder='l' onChange={(e) => uploadFile(e)} type='file' style={{ marginTop: 8, width: '100px' }} />
           
        </div>
    )

    const optionsPaymentMethod = [
        { label: 'BOLETA', value: 'TICKET' },
        { label: 'FACTURA', value: 'INVOICE' },
    ]

    const addCourse = (course) => {
        // setLoadingCourse(true)
        onChangePaymentMethod('')
        let a_courses = courses
        let price = 0
        let results = a_courses.filter(function (c) { return c.idProgramming == course.idProgramming; });

        if (results.length == 0) {

            a_courses.push(course)

        } else {

            a_courses = a_courses.filter(function (c) { return c.idProgramming != course.idProgramming; });
        }

        a_courses.map((c) => {
            price = parseFloat(c.price) + parseFloat(price)
        })

        let a_rules = rulesSeguriton.filter(function (c) { return c.amount == a_courses.length; });
        if (a_rules[0]) {
            setDiscount(a_rules[0].discount)
            setTotal((price - a_rules[0].discount))

        } else {
            setDiscount('0.00')
            setTotal((price))

        }
        setCourses([...a_courses])
    }

    return (
        <Layout>

            <NavBar />
            {saveSuccess === 0 ?
                <Content className="site-layout">
                    <Row style={{ marginRight: 'auto', marginLeft: 'auto', justifyContent: 'center' }}>
                        {coursesSeguriton.length > 0 ?
                            <Col lg={16} xs={24} sm={24} md={24} style={{ width: '100%' }}>
                                <Card className='card-info-course gx-card' style={{ marginBottom: '1rem' }}>
                                    <Row >
                                        <Col xs={24} style={{ textAlign: 'center', fontSize: '20px', marginBottom: '10px' }}>  <label className='orange_iteprevengo' ><b>Seleccione los cursos del Seguriton</b></label></Col>

                                        <Col xs={24} >
                                            <table className='table-courses-seguriton' width='100%'>

                                                <tbody>

                                                    {coursesSeguriton.length > 0 ? coursesSeguriton.map((course) => {
                                                        return <tr>

                                                            <td width='60%' ><Checkbox disabled={loadingCourse} onChange={() => addCourse(course)} /> {course.course_name} Modalidad:{course.modality}
                                                            </td>
                                                            <td width='20%' style={{ textAlign: 'center' }}><CalendarFilled />  {course.startDate}  {course.startHour}</td>
                                                            <td width='20%' style={{ textAlign: 'right' }}>S/. {course.price}</td>
                                                        </tr>
                                                    }) : ''}


                                                </tbody>
                                            </table>
                                            <br></br> <br></br>
                                            {loadingCourse ? <Spin color='#072d70' tip="Añadiendo curso..."> </Spin> : ''}
                                            <br></br><br></br>
                                        </Col>


                                    </Row>

                                </Card>
                            </Col> : ''}
                        <Col lg={16} xs={24} sm={24} md={24} style={{ width: '100%' }}>
                            <Card className='card-info-course gx-card' style={{ marginBottom: '1rem' }}>
                                <Row >
                                    <Col xs={24} style={{ textAlign: 'center', fontSize: '20px', marginBottom: '10px' }}>  <label className='orange_iteprevengo' ><b>Detalle del pedido</b></label></Col>

                                    <Col xs={24} >
                                        <table className='table-payment' width='100%'>


                                            <thead>
                                                <tr>
                                                    <td width='70%' >Descripción</td>
                                                    <td width='8%'>Cant.</td>
                                                    <td width='30%' >Precio</td>
                                                </tr>

                                            </thead>

                                            <tbody>

                                                {courses.length > 0 ? courses.map((course) => {
                                                    return <tr>
                                                        <td width='60%' >{course.course_name} Modalidad:{course.modality}
                                                        </td>
                                                        <td width='10%' style={{ textAlign: 'center' }}>1</td>
                                                        <td width='30%' style={{ textAlign: 'right' }}>S/. {course.price}</td>
                                                    </tr>
                                                }) : ''}


                                            </tbody>
                                        </table>
                                    </Col>

                                    

                                                



                                    <Col xs={24} style={{ justifyContent: 'right' }}>
                                        <br></br>

                                        <table style={{ textAlign: 'right' }} width='100%'>
                                                
                                            <tbody>

                                                { /*
                                                <tr>
                                                    <td width='60%' ><p><b>Descuento: </b></p></td>
                                                    <td width='20%' ><p><b>S/. {discount}</b> </p></td>
                                                </tr>

                                                <tr>
                                                    <td width='60%' ><p><b>Subtotal: </b></p></td>
                                                    <td width='20%' ><p><b>S/. {(total - (total * 0.18)).toFixed(2)}</b> </p></td>
                                                </tr>

                                                <tr>
                                                    <td width='60%' ><p><b>IGV(%18):</b></p></td>
                                                    <td width='20%' ><p><b>S/. {(total * 0.18).toFixed(2)}</b> </p></td>
                                                </tr>
                                            */ }
                                                <tr>
                                                    <td width='60%' ><p><b>Total:</b></p></td>
                                                    <td width='20%' > <p><b> S/. {(total * 1).toFixed(2)} </b> </p>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </Col>

                                    <Col xs={24} style={{ textAlign: 'center',display:'none' }}> <br></br>  <img className='logo-cards' src={logosCards} /> </Col>
                                    <Col xs={24} >
                                        <br></br>
                                        <p><QuestionCircleOutlined />Pago en soles (S/.) correspondiente a Perú. </p>

                                    </Col>
                                </Row>




                            </Card>
                        </Col>
                        {!authUser ?

                            <Col lg={16} xs={24} sm={24} md={24} style={{ width: '100%' }}>
                                <Card className='card-info-course' style={{ marginBottom: '1rem' }}>
                                    <Row >
                                        <Col xs={24} md={24} style={{ textAlign: 'center', fontSize: '20px', marginBottom: '10px' }}>  <label className='orange_iteprevengo' ><b>Datos del participante</b></label></Col>
                                        <Col xs={24} md={24} lg={6} > * <span className='blue_iteprevengo' ><b>Tipo de documento</b></span></Col>
                                        <Col xs={24} md={24} lg={18} > <span className='blue_iteprevengo'>  <Select size='' onChange={handleChangeTypeDocument} style={{ width: '100%' }} >
                                            <Select.Option value="DNI">DNI</Select.Option>
                                            <Select.Option value="PASAPORTE">PASAPORTE</Select.Option>
                                            <Select.Option value="CARNET">CARNET</Select.Option>


                                        </Select></span></Col>
                                        <Col xs={24} md={24} lg={6} > * <span className='blue_iteprevengo' ><b>Nro. de documento</b></span></Col>
                                        <Col xs={24} md={24} lg={18} >  <Input size='' value={nroDoc} maxLength={typeDoc === "DNI" ? 8 : 12} minLength={typeDoc === "DNI" ? 8 : 5} value={nroDoc} onChange={handleChangeClientDocument} /></Col>
                                        <Col xs={24} md={24} lg={6} > * <span className='blue_iteprevengo' ><b>Nombres</b></span></Col>
                                        <Col xs={24} md={24} lg={18} > <Input size='' onChange={(e) => setNamePerson(e.target.value)} /></Col>
                                        <Col xs={24} md={24} lg={6} >  *<span className='blue_iteprevengo' ><b>Apellido Paterno</b></span></Col>
                                        <Col xs={24} md={24} lg={18} > <Input size='' onChange={(e) => setLastName1(e.target.value)} /></Col>
                                        <Col xs={24} md={24} lg={6} > * <span className='blue_iteprevengo' ><b>Apellido Materno</b></span></Col>
                                        <Col xs={24} md={24} lg={18} > <Input size='' onChange={(e) => setLastName2(e.target.value)} /></Col>
                                        <Col xs={24} md={24} lg={6} > * <span className='blue_iteprevengo' ><b>Correo</b></span></Col>
                                        <Col xs={24} md={24} lg={18} > <Input size='' onChange={(e) => setEmail(e.target.value)} /></Col>
                                        <Col xs={24} md={24} lg={6} > <span className='blue_iteprevengo' ><b>Celular</b></span></Col>
                                        <Col xs={24} md={24} lg={18} > <Input size='' value={phone} maxLength='12' onChange={(e) => setPhone(e.target.value.replace(/\D/, ''))} /></Col>
                                        <br></br><br></br>
                                        <Col xs={24} md={24} > <span><b>Nota:</b> Verifique que sus datos sean correctos, a partir de ellos se le emitirá el certificado.</span></Col>
                                        <Col xs={24} md={24} > <span><b>Datos requeridos (*).</b></span></Col>


                                    </Row>

                                </Card>
                                
                            </Col> : ''}
                        {(programmingData.participantPrice === "0.00" || programmingData.participantPrice === "GRATUITO") ? null :
                            <>
                                <Col lg={16} xs={24} sm={24} md={24} style={{ width: '100%' }}>
                                    <Card className='card-info-course gx-card' style={{ marginBottom: '1rem' }}>
                                        <Row >
                                            <Col xs={24} style={{ textAlign: 'center', fontSize: '20px', marginBottom: '10px' }}>  <label className='orange_iteprevengo' ><b>Comprobante</b></label></Col>
                                            <Col xs={24} lg={24} style={{ textAlign: 'center', padding: '8x 0 18px 0' }}>
                                                <Radio.Group
                                                    options={optionsPaymentMethod}
                                                    onChange={(e) => setVoucherType(e.target.value)}
                                                    optionType="button"
                                                    buttonStyle="solid"
                                                    value={voucherType}
                                                />

                                            </Col>
                                            <Col xs={24} style={voucherType == 'INVOICE' ? { display: "none" } : {}} >
                                                <br></br>
                                                <span><b>Nota:</b> Se emitirá su boleta con sus datos ingresados.</span></Col>

                                            <Col xs={6} style={voucherType != 'INVOICE' ? { display: "none" } : {}} > <span className='blue_iteprevengo' ><b>Ruc</b></span></Col>
                                            <Col xs={18} style={voucherType != 'INVOICE' ? { display: "none" } : {}} > <Input size='' value={invoiceRuc} onChange={(e) => setInvoiceRuc(e.target.value.replace(/\D/, ''))} maxLength='12' /></Col>
                                            <Col xs={6} style={voucherType != 'INVOICE' ? { display: "none" } : {}}> <span className='blue_iteprevengo' ><b>Rázon social</b></span></Col>
                                            <Col xs={18} style={voucherType != 'INVOICE' ? { display: "none" } : {}} > <Input size='' onChange={(e) => setInvoiceBusinessName(e.target.value)} /></Col>

                                        </Row>

                                    </Card>
                                </Col>
                                <Col lg={16} xs={24} sm={24} md={24} style={{ width: '100%' }}>
                                    <Card className='card-payment' style={{ marginBottom: '1rem', justifyContent: 'center' }}>
                                        <Row >

                                            <Col xs={24} style={{ textAlign: 'center', fontSize: '20px', marginBottom: '10px' }}> <label className='orange_iteprevengo' ><b>Métodos de pago</b></label></Col>


                                            <Col xs={24} lg={18} style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto',display:'none' }}> <p>Elija el método de pago de su preferencia. </p>
                                                <img className='logo-cards' src={logosCards} />
                                                <br></br><br></br>
                                            </Col>



                                            <Col xs={24}>
                                                <Collapse
                                                    accordion
                                                    bordered={false}
                                                    activeKey={typePayment}
                                                    onChange={(key) => { onChangePaymentMethod(key) }}
                                                    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                                    className="site-collapse-custom-collapse"
                                                >
                                                    <Panel style={{ display:'none' }} header={<><CreditCardFilled /> <b style={{ fontSize: '20px', color: '#072d70' }}>Niubiz</b></>} key="NIUBIZ" className="site-collapse-custom-panel">
                                                        <p><QuestionCircleOutlined /> Al comprar a tráves de NIUBIZ podras acceder inmediatamente al curso . </p>
                                                        <br></br>
                                                        <Card className='card-payment ' style={showForm ? { backgroundColor: '#E5E6E6 ', padding: '2px' } : { display: 'none' }}>
                                                            <Row >

                                                                <Col xs={22} lg={24} style={{ textAlign: 'center' }}>
                                                                    <br></br>
                                                                    <img width='150px' src={logoNiubiz} />
                                                                </Col>
                                                                <Col xs={23}>
                                                                    <br></br>
                                                                </Col>

                                                                <Col xs={23} lg={22} style={{ marginLeft: '5px', textAlign: 'left' }}>
                                                                    <div className=' input-niubiz' >
                                                                        <div id="txtNumeroTarjeta" ></div>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={23} lg={11} style={{ marginLeft: '5px' }}>
                                                                    <div className='  input-niubiz' >
                                                                        <div id="txtFechaVencimiento" ></div>
                                                                    </div>
                                                                </Col>


                                                                <Col xs={23} lg={11} style={{ marginLeft: '5px' }}>
                                                                    <div className='  input-niubiz' >
                                                                        <div id="txtCvv" ></div>
                                                                    </div>
                                                                </Col>

                                                                <Col xs={23} lg={11} >
                                                                    <Input className='input-niubiz' style={{ backgroundColor: '#E5E6E6 ' }} placeholder='Nombre' onChange={(e) => setNameNiubiz(e.target.value)} />

                                                                </Col>

                                                                <Col xs={23} lg={11} style={!responsive ? { marginLeft: '5px' } : {}} >
                                                                    <Input className='input-niubiz' style={{ backgroundColor: '#E5E6E6 ' }} placeholder='Apellidos' onChange={(e) => setLNameNiubiz(e.target.value)} />

                                                                </Col>

                                                                <Col xs={23} lg={22}>
                                                                    <Input className='input-niubiz' style={{ backgroundColor: '#E5E6E6 ' }} placeholder='Correo' onChange={(e) => setEmailNiubiz(e.target.value)} />

                                                                </Col>



                                                                <Col xs={22} lg={22} style={{ textAlign: 'center' ,display:'none'}}>
                                                                    <br></br>
                                                                    <img className='logo-cards' src={logosCards} />

                                                                </Col>



                                                            </Row>
                                                        </Card>


                                                        <Card className='card-payment ' style={!showForm ? { backgroundColor: '#E5E6E6 ', textAlign: 'center', color: '#000' } : { display: 'none' }}>
                                                            {courses.length > 0 ?

                                                                <>Obteniendo formulario de pago ...
                                                    <br></br>
                                                                    <Skeleton active paragraph={{ rows: 4 }} /> </> : 'Selecione un curso'}

                                                        </Card>
                                                    </Panel>
                                                    <Panel header={<><BankOutlined /> <b style={{ fontSize: '20px', color: '#072d70' }}>Transferencia Bancaria</b></>} key="TRANSFER" className="site-collapse-custom-panel">
                                                        <Card className='card-payment '>
                                                            <Row >
                                                                <Col xs={24} >
                                                                    <div id='wire-info'>
                                                                        <div dangerouslySetInnerHTML={{ __html: wayToPay }} />

                                                                    </div>
                                                                </Col>
                                                                <Col xs={24} style={{ textAlign: 'center', margin: '10px' }}>
                                                                    <p><QuestionCircleOutlined /> Validaremos tu voucher dentro de las próximas 24h hábiles, se te enviará un correo de confirmación</p>
                                                                    {/* <Upload {...props_upload}
                                                                        listType="picture-card"
                                                                        className="avatar-uploader"
                                                                        beforeUpload={beforeUpload}
                                                                    > */}
                                                                    {voucher ? <img src={process.env.REACT_APP_PATH + voucher.url} alt="avatar" style={{ width: '170px ', }} /> : ''}
                                                                    {uploadButton}
                                                                    {/* </Upload> */}

                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </Panel>

                                                    <Panel header={<><ExpandOutlined /> <b style={{ fontSize: '20px', color: '#072d70' }}>Yape / Plin</b></>} key="yape" className="site-collapse-custom-panel">
                                                    <Card className='card-payment '>
                                                            <Row >

                                                                <Col xs={24} style={{ textAlign: 'center' }}>
                                                                    <p className='blue_iteprevengo'>Pago a: P&M SUPPORT S.R.L</p>
                                                                    <p >Número de yape o plin: 943776124</p>
                                                                </Col>
                                                                <Col xs={24} style={{ textAlign: 'center' }}>

                                                                    <img src={yapeImg} style={{ width: '100%', maxWidth: '400px' }} />
                                                                </Col>
                                                                <Col xs={24} style={{ textAlign: 'center', margin: '10px' }}>
                                                                    <p><QuestionCircleOutlined /> Validaremos tu voucher dentro de las próximas 24h hábiles, se te enviará un correo de confirmación</p>
                                                                    {/* <Upload {...props_upload}
                                                                        listType="picture-card"
                                                                        className="avatar-uploader"
                                                                        beforeUpload={beforeUpload}
                                                                    > */}
                                                                    {voucher ? <img src={process.env.REACT_APP_PATH + voucher.url} alt="avatar" style={{ width: '170px ', }} /> : ''}
                                                                    {uploadButton}
                                                                    {/* </Upload> */}
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </Panel>

                                                   { /* <Panel style={{display:'none'}} header={<><FileDoneOutlined /> <b style={{ fontSize: '20px', color: '#072d70' }}>YAPE</b></>} key="YAPE" className="site-collapse-custom-panel">
                                                        <Card className='card-payment '>
                                                            <Row >

                                                                <Col xs={24} style={{ textAlign: 'center' }}>
                                                                    <p className='blue_iteprevengo'>Pago a: P&M SUPPORT S.R.L</p>
                                                                </Col>
                                                                <Col xs={24} style={{ textAlign: 'center' }}>

                                                                    <img src={yapeImg} style={{ width: '100%', maxWidth: '400px' }} />
                                                                </Col>
                                                                <Col xs={24} style={{ textAlign: 'center', margin: '10px' }}>
                                                                    <p><QuestionCircleOutlined /> Validaremos tu voucher en las próximas 48 h, se te enviará un correo de confirmación</p>
                                                                    
                                                                    {voucher ? <img src={process.env.REACT_APP_PATH + voucher.url} alt="avatar" style={{ width: '170px ', }} /> : ''}
                                                                    {uploadButton}
                                                                    
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                                </Panel> */}
                                                </Collapse>
                                            </Col>                     

                                        </Row>

                                    </Card>
                                </Col>
                            </>
                        }
                        <Col xs={24} style={{ textAlign: 'center' }}>
                            <br></br>
                            <Checkbox checked={checkTerms} onChange={(e) => setCheckTerms(e.target.checked)} style={{ fontSize: '1rem' }}>
                                Acepto los  <a target='_blank' href='https://www.iteprevengo.com/terminos-y-condiciones-de-compra/'>términos y condiciones de pago.</a>
                            </Checkbox>

                        </Col>
                        <Col xs={24} style={{ textAlign: 'center' }}>


                            {
                                typePayment == 'NIUBIZ' ? <>
                                    {errorNiubiz.message != undefined ?
                                        <div style={{ textAlign: 'center', color: 'red' }} >
                                            <br></br>
                                            <p>{errorNiubiz.purchaseNumber != undefined ? <> No pudimos procesar su pedido con N°:' {errorNiubiz.purchaseNumber}.</> : ''} {errorNiubiz.message}</p>
                                        </div>
                                        : ''}


                                    <Button onClick={payVisa} loading={loading} name='btn-save' className='btn-process'>Procesar pago S/.{session.amount}</Button>
                                </>
                                    :

                                    <button onClick={save} name='btn-save' className='btn-process'>Procesar pago</button>

                            }
                            {/* <Button onClick={resetPayment} name='btn-save' className='btn-orange-iteprevengo'>Cancelar</Button> */}


                            {loading ?

                                <p>Esto puede tardar un momento. Espere mientras se procesa la transacción</p>

                                : ''}




                        </Col>

                        
                    </Row>

                                


                </Content>
                :
                <Content className="site-layout">
                    <Row style={responsive ? { width: '100%' } : { marginRight: 'auto', marginLeft: 'auto', width: '60%' }}>
                        <Col lg={23} xs={24} style={{ width: '100%' }}>
                            <Card className='card-info-course gx-card' style={{ marginBottom: '1rem', textAlign: 'center' }}>
                                <img src={checkedSVG} width="100" />
                                <h3 className="orange_iteprevengo">{saveSuccessData.text1}</h3>
                                {saveSuccessData.text2 ? <p className="blue_iteprevengo"><CheckCircleOutlined className="orange_iteprevengo" /> {saveSuccessData.text2}</p> : null}
                                <br></br>
                                {saveSuccessData.text3 ? <p><MailFilled className="blue_iteprevengo" /> {saveSuccessData.text3}</p> : null}
                                <br></br>
                                {typePayment == 'NIUBIZ' ?
                                    <>
                                        <Row>
                                            <Col xs={24} >
                                                <br></br>

                                                <table className='table-payment-data' style={{ textAlign: 'left' }} width='100%'>

                                                    <tbody>

                                                        <tr  >
                                                            <td width='70%' style={{ padding: '5px' }}><p><b>N° de pedido:</b></p></td>
                                                            <td width='30%' style={{ textAlign: 'right' }} > <p><b>  {(saveSuccessData.purchaseNumber)} </b> </p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td width='70%' style={{ padding: '5px' }} ><p><b>N° de tarjeta:</b></p></td>
                                                            <td width='30%' style={{ textAlign: 'right' }} > <p><b>  {(saveSuccessData.card)} </b> </p>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                                <br></br><br></br>
                                            </Col>
                                            <Col xs={24} >
                                                <p style={{ textAlign: 'left', fontSize: '18px' }}> <b>Cursos:</b></p>

                                                <table className='table-payment' style={{ fontSize: '13px ' }} width='100%'>

                                                    <tbody>

                                                        {courses.length > 0 ? courses.map((course) => {
                                                            return <tr>
                                                                <td width='70%' style={{ textAlign: 'left' }}>{course.course_name} Modalidad:{course.modality}
                                                                </td>
                                                                <td width='30%' style={{ textAlign: 'right' }}>S/. {course.price}</td>
                                                            </tr>
                                                        }) : ''}

                                                    </tbody>
                                                </table>
                                            </Col>
                                            <Col xs={24} style={{ justifyContent: 'right' }}>
                                                <br></br>

                                                <table style={{ textAlign: 'right' }} width='100%'>

                                                    <tbody>
                                                        {/* <tr>
                                                            <td width='60%' ><p><b>Descuento: </b></p></td>
                                                            <td width='10%' ><p><b>S/. {saveSuccessData.discount}</b> </p></td>
                                                        </tr>
                                                        <tr>
                                                            <td width='80%' ><p><b>Subtotal: </b></p></td>
                                                            <td width='20%' ><p><b>S/. {saveSuccessData.subtotal}</b> </p></td>
                                                        </tr>

                                                        <tr>
                                                            <td width='80%' ><p><b>IGV(%18):</b></p></td>
                                                            <td width='20%' ><p><b>S/. {(saveSuccessData.igv)}</b> </p></td>
                                                        </tr> */}

                                                        <tr>
                                                            <td width='80%' ><p><b>Total:</b></p></td>
                                                            <td width='20%' > <p><b> S/. {(saveSuccessData.total)} </b> </p>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </Col>
                                            <Col xs={24}>
                                                <br></br>
                                                Le invitamos a ver nuestros términos y condiciones de pago  <a target='_blank' href='https://www.iteprevengo.com/terminos-y-condiciones-de-compra/'>aquí</a>

                                            </Col>

                                        </Row>

                                        <br></br>

                                        <Button onClick={() => window.open(saveSuccessData.url_pdf)} icon={<DownloadOutlined />} className='btn-blue-iteprevengo'>Descargar pago</Button>
                                        <br></br>
                                    </>
                                    :
                                    ''}
                                <Link to="/Login">
                                    <button className="btn-orange-iteprevengo">Ingresar al aula virtual</button>
                                </Link>
                            </Card>
                        </Col>
                    </Row>
                </Content>}
            <Footer style={{ textAlign: 'center' }}>© ITEPREVENGO 2021</Footer>
        </Layout>
    );
};


export default Inscription;