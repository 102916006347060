import React, { useEffect, useState } from "react";
import "../styles/login.scss";
import { Form, Input, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import InfoView from "../components/InfoView";
import { Link } from 'react-router-dom';
import logo from "../imgs/logo_pacifico.png";
import { MailOutlined } from '@ant-design/icons';
import axios from '../util/Api'

import { forgetPassword } from "../appRedux/actions/Auth";

const ForgotPassword = (props) => {
    const dispatch = useDispatch();
    //const forgotPassword = useSelector(({ auth }) => auth.forgotPassword);
    const [forgotPassword, setForgotPassword] = useState(null);
    const [showSend, setShowSend] = useState(false);

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const onFinish = (values) => {
        axios.post('auth/forgotPassword', {
            users_email_phone: values.users_email_phone
        },
          {
            headers: {
              "X-Requested-With": "XMLHttpRequest"
            }
          }
        ).then(({ data, status }) => {
          if (status==200) {
            setShowSend(true)
            setForgotPassword(data.email)
          } else {
            message.error(data.error);
          }
    
    
        }).catch(function (error) {
            console.log("Error****:", error.response.data.message);
            if(error.response){
              message.error(error.response.data.message);            
            }else{
              message.error(error.message);            
          }
        });
        //dispatch(forgetPassword(values));
    };

    return (
        <div className="body login-content">
            <Link to="/welcome">
                <img className="logo-ini" src={logo} />
            </Link>
            {!showSend ?

            <div className="card-login">
                <p style={{textAlign: 'center', color: '#4a4a4a'}} className="grey-link">¿Deseas recuperar tu contraseña?</p>
                <Form
                    initialValues={{ remember: true }}
                    name="basic"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    className="gx-signin-form gx-form-row0"
                >
                    <Form.Item
                        rules={[
                            { required: true, message: "Usuario o email requerido." },
                        ]}
                        name="users_email_phone"
                    >
                        <Input
                            placeholder="Ingresa tu usuario o email"
                            className="password_input"
                            allowClear
                        />
                    </Form.Item>
                    <Form.Item style={{ textAlign: "center", margin: 0 }}>
                        <button className="btn-orange" type="submit">
                            Recuperar
                        </button>
                    </Form.Item>
                </Form>
            </div>
            :
            <div className="card-login" style={{textAlign: 'center'}}>
                <p style={{paddingBottom: '1rem'}}><MailOutlined style={{marginRight: '.4rem'}} />Se ha enviado un correo a {forgotPassword} con sus credenciales.</p>
                <Link to='/login' className="btn-forgot-back">Regresar</Link>
            </div>
            }
        </div>
    );
};

export default ForgotPassword;
