import React,{useState,useEffect} from "react";
import { Link } from 'react-router-dom';
import { Layout, Menu } from "antd";
import { PoweroffOutlined, UserOutlined } from '@ant-design/icons';

import "../../styles/kernel.scss";

import {userSignOut} from "../../appRedux/actions/Auth";
import {useDispatch, useSelector} from "react-redux";

const { Header} = Layout;

const NavBar = () => {
    const dispatch = useDispatch();
    
    return(
        <Header className="navBar" style={{ position: 'fixed', zIndex: 10, width: '100%', background: 'white' }}>
            <a href="https://www.iteprevengo.com/cursos-virtuales/" ><div className="logo" /></a>
          
        
        </Header>
    );

}

export default NavBar;