import React, { useState, useEffect } from "react";
import {
    Card,
    Form,
    Input,
    Button,
    Row,
    Col,
    Tag,
    message,
    Divider,
    Skeleton,
    Carousel,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
    DownloadOutlined,
    MailOutlined,
    ShoppingCartOutlined,
    UserOutlined,
    HistoryOutlined,
    CalendarOutlined,
    CalendarFilled,
    DashboardOutlined,
    FileTextOutlined,
    ProfileOutlined,
    TrophyOutlined,
    TeamOutlined,
    StarFilled,
    StarOutlined,
    BookOutlined,
} from "@ant-design/icons";

import { useLocation, Link, Redirect } from "react-router-dom";
import { useHistory } from "react-router";

import axios from "../util/Api";
import star from "../assets/svg/star.ico";

import "../styles/kernel.scss";
import "../styles/course.scss";
import { setResponsive } from "../appRedux/actions/Responsive";
import NavBar from "../components/NavBarPublic";
import Comment from "./Comment";
import Seguriton from "../components/Seguriton";
import { Layout } from "antd";
import cateImg from '../imgs/categoria.png';

import ExpertPhotoDefault from "../assets/images/avatar.jpg";
import DiaryItem from "../components/DiaryItem";

import cateImg1 from '../imgs/Íconos 01.png';
import cateImg2 from '../imgs/Íconos 02.png';
import cateImg3 from '../imgs/Íconos 03.png';
import cateImg4 from '../imgs/Íconos 04.png';


const contentStyle = {
    margin: 0,
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
};

const { Content, Footer } = Layout;



{ /*
// Crear un elemento script para cargar la biblioteca Font Awesome
var script = document.createElement('script');
script.src = 'https://kit.fontawesome.com/eb496ab1a0.js';
script.crossOrigin = 'anonymous';
document.head.appendChild(script);

// Esperar a que se cargue la biblioteca Font Awesome antes de crear el botón
script.onload = function () {
  // Crear el botón de WhatsApp
  var whatsappButton = document.createElement('a');
  whatsappButton.href = 'https://wa.me/51943776124';
  whatsappButton.className = 'btn-wsp';
  whatsappButton.target = '_blank';

  // Crear el icono de WhatsApp
  var whatsappIcon = document.createElement('i');
  whatsappIcon.className = 'fa fa-whatsapp icono';

  // Agregar estilos al botón
  whatsappButton.style.position = 'fixed';
  whatsappButton.style.width = '60px';
  whatsappButton.style.height = '60px';
  whatsappButton.style.lineHeight = '63px';
  whatsappButton.style.bottom = '25px';
  whatsappButton.style.right = '25px';
  whatsappButton.style.background = '#25d366';
  whatsappButton.style.color = '#FFF';
  whatsappButton.style.borderRadius = '50px';
  whatsappButton.style.textAlign = 'center';
  whatsappButton.style.fontSize = '35px';
  whatsappButton.style.boxShadow = '0px 1px 10px rgba(0,0,0,0.3)';
  whatsappButton.style.zIndex = '100';
  whatsappButton.style.transition = 'all 300ms ease';

  // Agregar estilos al botón al pasar el mouse por encima
  whatsappButton.addEventListener('mouseover', function () {
    whatsappButton.style.background = '#20ba5a';
  });

  // Agregar estilos al botón al dejar de pasar el mouse por encima
  whatsappButton.addEventListener('mouseout', function () {
    whatsappButton.style.background = '#25d366';
  });

  // Adjuntar el icono al botón
  whatsappButton.appendChild(whatsappIcon);

  // Adjuntar el botón al cuerpo del documento
  document.body.appendChild(whatsappButton);
};


*/}







const Course = () => {
    const onChange = (currentSlide) => {
        console.log(currentSlide);
    };
    const query = new URLSearchParams(useLocation().search);
    const id = query.get("id");
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const responsive = useSelector((store) => store.responsive.value);

    const [tabActive, setTabActive] = useState(0);
    let history = useHistory();
    const [contentCourse, setContentCourse] = useState("");
    const [contentLearning, setContentLearning] = useState("");
    const [contentExpert, setContentExpert] = useState("");
    const [category, setCategory] = useState([]);
    const [priceNew, setPriceNew] = useState("");
    const [priceOld, setPriceOld] = useState("");
    const [expert, setExpert] = useState([]);
    const [duration, setDuration] = useState(0);
    const [numberStudents, setNumberStudents] = useState(0);
    const [numberModules, setNumberModules] = useState(0);
    const [updateDate, setUpdateDate] = useState("");
    const [name, setName] = useState("");
    const [brochure, setBrochure] = useState("");
    const [videoHtml, setVideoHtml] = useState("");
    const [certificate, setCertificate] = useState("");
    const [calification, setCalification] = useState("");
    const [usersCalification, setUsersCalification] = useState("");
    const [target, setTarget] = useState("");
    const [modality, setModality] = useState("");
    const [startDate, setstartDate] = useState("");
    const [endDate, setendDate] = useState("");
    const [diaries, setDiaries] = useState([]);
    const [enableSeguriton, setEnableSeguriton] = useState(false);
    const [seguriton, setSeguriton] = useState([]);
    const [arrayExpert, setArrayExpert] = useState([]);
    const [idProgramming, setIdProgramming] = useState([]);
    const [courses, setCourses] = useState([]);
    const [rulesSeguriton, setRulesSeguriton] = useState([]);

    const [comments, setComments] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dispatch(setResponsive());
        getCourse();
        console.log("responsive:", responsive);
    }, []);

    const getCourse = () => {


        axios
            .get("/external/programming/" + id, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                },
            })
            .then(({ data }) => {
                let programming = data.programming;
                setIdProgramming(programming.idProgramming);
                setName(programming.course.name);
                setCategory(
                    programming.course
                        ? programming.course.category
                            ? programming.course.category.name
                            : null
                        : null
                );
                setExpert(programming.expert);
                setPriceNew(programming.participantPrice);
                setPriceOld(programming.participantPriceOld);
                setDuration(programming.duration);
                setUpdateDate(programming.lastChangeDate);
                setNumberModules(programming.countModules);
                setNumberStudents(programming.countInscription <= 20 ? '20' : programming.countInscription);
                setCalification(parseFloat(programming.score) == 0 ? '5.0' : parseFloat(programming.score));
                setTarget(programming.course.target);
                setContentCourse(programming.course.description);
                setContentLearning(programming.course.learning);
                setContentExpert(programming.expert.summary);
                setComments(programming.comments);
                setUsersCalification(parseFloat(programming.score) == 0 ? null : programming.quantityUsersScore);
                setModality(programming.modality);
                setstartDate(programming.startDate);
                setendDate(programming.endDate);
                setDiaries(programming.diaries);
                setCertificate(programming.certificate_example);

                setEnableSeguriton(data.enableSeguriton);
                setSeguriton(data.seguriton);
                setCourses(data.courses);
                setRulesSeguriton(data.priceRules);

                if (data.enableSeguriton) {
                    let a_expert = [];
                    data.courses.map((d) => {
                        a_expert.push(d.expert);
                    });
                    const uniqueExperts = Array.from(
                        new Set(a_expert.map((a) => a.idExpert))
                    ).map((idExpert) => {
                        return a_expert.find((a) => a.idExpert === idExpert);
                    });
                    setArrayExpert(uniqueExperts);
                }

                //document.getElementById('content').innerHTML = programming.course.description
                //document.getElementById('expertInfo').innerHTML = programming.expert.summary
                //document.getElementById('video').innerHTML = programming.course.linkVideo ? programming.course.linkVideo : programming.course.photos
                //document.getElementById('learning').innerHTML = programming.course.learning
                setVideoHtml(
                    programming.course.linkVideo
                        ? programming.course.linkVideo
                        : programming.course.photos
                );
                setCertificate(programming.certificate_example);
                setBrochure(programming.brochure);

                setLoading(true);
            })
            .catch(function (error) {
                setLoading(true);
                console.log("Error****:", error.message);
            });
    };

    const handleChangeTab = (t) => {
        setTabActive(t);
    };

    const stars = () => {
        let a_stars = [];
        for (let i = 0; i < Math.floor(calification); i++) {
            a_stars.push(<StarFilled key={i} />);
        }

        if (calification < Math.round(calification)) {
            a_stars.push(
                <span className="orange_iteprevengo">
                    <img
                        className="orange_iteprevengo"
                        height="13.3px"
                        style={{ marginBottom: "5.1px" }}
                        src={star}
                    />
                </span>
            );
        }

        for (let i = 0; i < 5 - Math.round(calification); i++) {
            a_stars.push(<StarOutlined key={i} />);
        }

        return <>{a_stars}</>;



    };

    return (
        <div>
            <Layout>
                <NavBar />
                <Content className="course-layout">
                    {enableSeguriton ? (
                        <Seguriton
                            idProgramming={idProgramming}
                            seguriton={seguriton}
                            arrayExpert={arrayExpert}
                            courses={courses}
                            rules={rulesSeguriton}
                        />
                    ) : (
                        <>
                            <Content className="course-presentation">
                                <Row
                                    className="b-container wrap-presentation"
                                    style={{
                                        paddingTop: "1rem",
                                        paddingBottom: "1rem",
                                    }}
                                >
                                    <Col xs={24} sm={24} md={24} lg={16}>
                                        {loading === false ? (
                                            <Skeleton
                                                paragraph={{ rows: 10 }}
                                                active
                                            />
                                        ) : (
                                            <div style={{ padding: ".25rem" }}>
                                                <span className="label-category">
                                                    Categoría: {category}
                                                </span>
                                                <h1 className="title-course">
                                                    {name}
                                                </h1>
                                                <p className="subtitle-course">
                                                    {target}
                                                </p>
                                                {modality == "VIRTUAL" ? (
                                                    <>
                                                        <div className="course-extrainfo">
                                                            <span className="label-orange">
                                                                Más vendido
                                                            </span>
                                                            <span>
                                                                <b>
                                                                    {/*
                                                                        calification
                                                                        */
                                                                    }
                                                                </b>{" "}
                                                                {/*stars()*/}{/*usersCalification?<>( {" "}  <UserOutlined />  )</>:''*/}
                                                            </span>
                                                        </div>
                                                    </>
                                                ) : (
                                                    ""
                                                )}

                                                <ul className="list-features">
                                                    <li>
                                                        <span>
                                                            <BookOutlined />{" "}
                                                            <Tag color="#f99400">
                                                                {modality}
                                                            </Tag>
                                                        </span>
                                                    </li>

                                                    <li>
                                                        <span>
                                                            <UserOutlined />{" "}
                                                            {expert.firm}
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            <HistoryOutlined />{" "}
                                                            {duration} h
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            <TeamOutlined />{" "}
                                                            {numberStudents}{" "}
                                                            alumnos
                                                        </span>
                                                    </li>
                                                    {modality == "VIRTUAL" ? (
                                                        <>
                                                            <li>
                                                                <span>
                                                                    <ProfileOutlined />{" "}
                                                                    {
                                                                        numberModules
                                                                    }{" "}
                                                                    módulos
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span>
                                                                    <CalendarOutlined />{" "}
                                                                    Inicia hoy o cuando desees.
                                                                </span>
                                                            </li>
                                                        </>
                                                    ) : (
                                                        ""
                                                    )}

                                                    {modality == "ONLINE" ? (
                                                        <>
                                                            <li>
                                                                <span>
                                                                    <CalendarOutlined />{" "}
                                                                    Inicio:{" "}
                                                                    {startDate}
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span>
                                                                    <CalendarOutlined />{" "}
                                                                    Fin:{" "}
                                                                    {endDate}
                                                                </span>
                                                            </li>
                                                        </>
                                                    ) : (
                                                        ""
                                                    )}

                                                    <li>
                                                        <span>
                                                            <TrophyOutlined />{" "}
                                                            Certificado{" "}
                                                        </span>
                                                    </li>
                                                </ul>
                                                <div className="section-price">
                                                    <button
                                                        onClick={() =>
                                                            history.push(
                                                                "/inscription?id=" +
                                                                id
                                                            )
                                                        }
                                                        className="btn-buy"
                                                    >
                                                        {" "}
                                                        <ShoppingCartOutlined />{" "}
                                                        <b>Comprar</b>
                                                    </button>
                                                    <div className="d-flex alignItemsCenter">
                                                        <span className="text-price">
                                                            S/{priceNew}
                                                        </span>
                                                        {priceOld != "0.00" ? (
                                                            <span className="text-oldprice">
                                                                Antes{" "}
                                                                <span className="text-oldprice-textDecoration">
                                                                    S/{priceOld}
                                                                </span>
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Col>
                                    <Col
                                        className="center-section"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={8}
                                    >
                                        {loading === false ? (
                                            <Skeleton.Image />
                                        ) : (
                                            <div
                                                className="wrap-video"
                                                id=""
                                                style={{ textAlign: "center" }}
                                            >
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: videoHtml,
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                            </Content>

                            <Content className="b-container" >
                                <Row>
                                    <Col xs={24}>
                                        <Row>
                                            <Col xs={24}>
                                                <div

                                                >
                                                    <img src={cateImg} className="responsive-image" />


                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Content>


                            <Content className="responsive-carrucel">
                                <Carousel afterChange={onChange} >
                                    <div>
                                        <img src={cateImg1} alt="Imagen 1" />
                                    </div>
                                    <div>
                                        <img src={cateImg2} alt="Imagen 2" />
                                    </div>
                                    <div>
                                        <img src={cateImg3} alt="Imagen 3" />
                                    </div>
                                    <div>
                                        <img src={cateImg4} alt="Imagen 4" />
                                    </div>
                                </Carousel>
                            </Content>




                            <Content className="b-container">
                                {!responsive ? (
                                    <Row>
                                        <Col xs={24}>
                                            <Row>
                                                <Col xs={24}>
                                                    <div
                                                        className="tabs-group"
                                                        style={{
                                                            margin: "2rem 0 0 0",
                                                        }}
                                                    >
                                                        <div
                                                            onClick={() =>
                                                                handleChangeTab(
                                                                    0
                                                                )
                                                            }
                                                            className={
                                                                tabActive == 0
                                                                    ? `tab-item tab-item-active`
                                                                    : "tab-item"
                                                            }
                                                        >
                                                            {" "}
                                                            ¿Qué aprenderás?{" "}
                                                        </div>
                                                        <div
                                                            onClick={() =>
                                                                handleChangeTab(
                                                                    1
                                                                )
                                                            }
                                                            className={
                                                                tabActive == 1
                                                                    ? `tab-item tab-item-active`
                                                                    : "tab-item"
                                                            }
                                                        >
                                                            {" "}
                                                            Contenido
                                                        </div>
                                                        {certificate ? (
                                                            <div
                                                                onClick={() =>
                                                                    handleChangeTab(
                                                                        2
                                                                    )
                                                                }
                                                                className={
                                                                    tabActive ==
                                                                        2
                                                                        ? `tab-item tab-item-active`
                                                                        : "tab-item"
                                                                }
                                                            >
                                                                {" "}
                                                                Certificado{" "}
                                                            </div>
                                                        ) : null}
                                                        <div
                                                            onClick={() =>
                                                                handleChangeTab(
                                                                    3
                                                                )
                                                            }
                                                            className={
                                                                tabActive == 3
                                                                    ? `tab-item tab-item-active`
                                                                    : "tab-item"
                                                            }
                                                        >
                                                            {" "}
                                                            Instructor{" "}
                                                        </div>





                                                        {brochure ? (


                                                            <div
                                                                onClick={() =>
                                                                    handleChangeTab(
                                                                        4
                                                                    )
                                                                }
                                                                className={
                                                                    tabActive ==
                                                                        4
                                                                        ? `tab-item tab-item-active`
                                                                        : "tab-item"
                                                                }
                                                            >
                                                                {" "}
                                                                Brochure{" "}
                                                            </div>
                                                        ) : null}


                                                        {/*comments.length > 0 ? (
                                                            <div
                                                                onClick={() =>
                                                                    handleChangeTab(
                                                                        5
                                                                    )
                                                                }
                                                                className={
                                                                    tabActive ==
                                                                    5
                                                                        ? `tab-item tab-item-active`
                                                                        : "tab-item"
                                                                }
                                                            >
                                                                {" "}
                                                                Comentarios{" "}
                                                            </div>
                                                            ) : null*/}



                                                        {diaries.length > 0 &&
                                                            modality !=
                                                            "VIRTUAL" ? (
                                                            <div
                                                                onClick={() =>
                                                                    handleChangeTab(
                                                                        6
                                                                    )
                                                                }
                                                                className={
                                                                    tabActive ==
                                                                        6
                                                                        ? `tab-item tab-item-active`
                                                                        : "tab-item"
                                                                }
                                                            >
                                                                {" "}
                                                                Agenda{" "}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                                <Col
                                                    xs={24}
                                                    style={
                                                        tabActive == 0
                                                            ? {}
                                                            : {
                                                                display:
                                                                    "none",
                                                            }
                                                    }
                                                >
                                                    <Card
                                                        style={{
                                                            width: "100%",
                                                            textAlign:
                                                                "justify",
                                                        }}
                                                        className="card-info-course"
                                                    >
                                                        {loading === false ? (
                                                            <Skeleton active />
                                                        ) : (
                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html: contentLearning,
                                                                }}
                                                            />
                                                        )}
                                                    </Card>
                                                </Col>

                                                <Col
                                                    xs={24}
                                                    style={
                                                        tabActive == 1
                                                            ? {}
                                                            : {
                                                                display:
                                                                    "none",
                                                            }
                                                    }
                                                >
                                                    <Card
                                                        style={{
                                                            width: "100%",
                                                            textAlign:
                                                                "justify",
                                                        }}
                                                        className="card-info-course"
                                                    >
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: contentCourse,
                                                            }}
                                                        />
                                                    </Card>
                                                </Col>
                                                <Col
                                                    xs={24}
                                                    style={
                                                        tabActive == 2
                                                            ? {
                                                                textAlign:
                                                                    "center",
                                                            }
                                                            : {
                                                                display:
                                                                    "none",
                                                            }
                                                    }
                                                >
                                                    <Card
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        className="card-info-course"
                                                    >
                                                        <img
                                                            src={certificate}
                                                            className="image-certificate"
                                                        />
                                                    </Card>
                                                </Col>
                                                <Col
                                                    xs={24}
                                                    style={
                                                        tabActive == 3
                                                            ? {}
                                                            : {
                                                                display:
                                                                    "none",
                                                            }
                                                    }
                                                >
                                                    <Card
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        className="card-info-course"
                                                    >
                                                        <Row>
                                                            <Col
                                                                xs={24}
                                                                lg={6}
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                <img
                                                                    src={
                                                                        expert.photo
                                                                            ? expert.photo
                                                                            : ExpertPhotoDefault
                                                                    }
                                                                    className="photo-expert"
                                                                />
                                                            </Col>
                                                            <Col
                                                                xs={24}
                                                                lg={16}
                                                                style={{
                                                                    textAlign:
                                                                        "justify",
                                                                    margin: "10px",
                                                                }}
                                                            >
                                                                <span
                                                                    className="blue_iteprevengo"
                                                                    style={{
                                                                        fontSize:
                                                                            "20px",
                                                                    }}
                                                                >
                                                                    <b>
                                                                        {
                                                                            expert.firm
                                                                        }
                                                                    </b>
                                                                </span>
                                                                <br></br>
                                                                {/* <b><MailOutlined /> {expert.email}</b> */}
                                                                <br></br>
                                                                <div
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: contentExpert,
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </Col>
                                                <Col
                                                    xs={24}
                                                    style={
                                                        tabActive == 4
                                                            ? {
                                                                textAlign:
                                                                    "center",
                                                            }
                                                            : {
                                                                display:
                                                                    "none",
                                                            }
                                                    }
                                                >
                                                    <Card
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        className="card-info-course"
                                                    >
                                                        <Button
                                                            className="btn-orange-iteprevengo"
                                                            onClick={() => {
                                                                window.open(
                                                                    brochure
                                                                );
                                                            }}
                                                        >
                                                            <DownloadOutlined />{" "}
                                                            Descargar aquí
                                                        </Button>
                                                    </Card>
                                                </Col>
                                                <Col
                                                    xs={24}
                                                    style={
                                                        tabActive == 5
                                                            ? {}
                                                            : {
                                                                display:
                                                                    "none",
                                                            }
                                                    }
                                                >
                                                    <Card
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        className="card-info-course"
                                                    >
                                                        <Comment
                                                            commentsList={
                                                                comments
                                                            }
                                                        />

                                                        <br></br>
                                                    </Card>
                                                </Col>

                                                <Col
                                                    xs={24}
                                                    style={
                                                        tabActive == 6 &&
                                                            modality != "VIRTUAL"
                                                            ? {}
                                                            : {
                                                                display:
                                                                    "none",
                                                            }
                                                    }
                                                >
                                                    <Card
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        className="card-info-course"
                                                    >
                                                        <Row
                                                            style={{
                                                                justifyContent:
                                                                    "center",
                                                            }}
                                                        >
                                                            {diaries
                                                                ? diaries.map(
                                                                    (
                                                                        diary,
                                                                        index
                                                                    ) => {
                                                                        return (
                                                                            <DiaryItem
                                                                                diary={
                                                                                    diary
                                                                                }
                                                                                number={
                                                                                    index +
                                                                                    1
                                                                                }
                                                                            />
                                                                        );
                                                                    }
                                                                )
                                                                : ""}
                                                        </Row>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                ) : (
                                    <>
                                        <div className="card-info">
                                            <h2>Que aprenderás</h2>
                                            <div className="card-info-body">
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: contentLearning,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="card-info">
                                            <h2>CONTENIDO Y EXAMEN</h2>
                                            <div className="card-info-body">
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: contentCourse,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {certificate ? (
                                            <div className="card-info">
                                                <h2>Certificado</h2>
                                                <div className="card-info-body">
                                                    <img
                                                        src={certificate}
                                                        className="image-certificate"
                                                    />
                                                </div>
                                            </div>
                                        ) : null}

                                        <div className="card-info">
                                            <h2>Especialista</h2>
                                            <div className="card-info-body">
                                                <Row>
                                                    <Col
                                                        xs={24}
                                                        lg={6}
                                                        style={{
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        <img
                                                            src={
                                                                expert.photo
                                                                    ? expert.photo
                                                                    : ExpertPhotoDefault
                                                            }
                                                            className=" "
                                                        />
                                                    </Col>
                                                    <Col
                                                        xs={24}
                                                        lg={16}
                                                        style={{
                                                            textAlign:
                                                                "justify",
                                                        }}
                                                    >
                                                        <span
                                                            className="blue_iteprevengo"
                                                            style={{
                                                                fontSize:
                                                                    "20px",
                                                            }}
                                                        >
                                                            <b>{expert.firm}</b>
                                                        </span>
                                                        <br></br>
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: contentExpert,
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>


                                        <div className="section-price">
                                            <button
                                                onClick={() =>
                                                    history.push(
                                                        "/inscription?id=" +
                                                        id
                                                    )
                                                }
                                                className="btn-buy"
                                            >
                                                {" "}
                                                <ShoppingCartOutlined />{" "}
                                                <b>Comprar</b>
                                            </button>
                                            {/* <div className="d-flex alignItemsCenter">
                                                        <span className="text-price">
                                                            S/{priceNew}
                                                        </span>
                                                        {priceOld != "0.00" ? (
                                                            <span className="text-oldprice">
                                                                Antes{" "}
                                                                <span className="text-oldprice-textDecoration">
                                                                    S/{priceOld}
                                                                </span>
                                                            </span>
                                                        ) : null} 
                                                    </div> */}
                                        </div>


                                        {brochure ? (
                                            <div className="card-info">
                                                <h2>Brochure</h2>
                                                <div className="card-info-body">
                                                    <Button
                                                        className="btn-orange-iteprevengo"
                                                        onClick={() => {
                                                            window.open(
                                                                brochure
                                                            );
                                                        }}
                                                    >
                                                        <DownloadOutlined />{" "}
                                                        Descargar aquí
                                                    </Button>
                                                </div>
                                            </div>
                                        ) : null}

                                        <div
                                            xs={24}
                                            style={
                                                tabActive == 6
                                                    ? {}
                                                    : { display: "none" }
                                            }
                                        >
                                            <div
                                                style={{ width: "100%" }}
                                                className="card-info"
                                            >
                                                <h2>AGENDA</h2>

                                                <Row
                                                    style={{
                                                        justifyContent:
                                                            "center",
                                                    }}
                                                >
                                                    {diaries
                                                        ? diaries.map(
                                                            (
                                                                diary,
                                                                index
                                                            ) => {
                                                                return (
                                                                    <DiaryItem
                                                                        diary={
                                                                            diary
                                                                        }
                                                                        number={
                                                                            index +
                                                                            1
                                                                        }
                                                                    />
                                                                );
                                                            }
                                                        )
                                                        : ""}
                                                </Row>
                                            </div>
                                        </div>
                                        {/*comments.length > 0 ? (
                                            <div className="card-info">
                                                <h2>Comentarios</h2>
                                                <div className="card-info-body">
                                                    <Comment
                                                        commentsList={comments}
                                                    />
                                                </div>
                                            </div>
                                        ) : null*/}
                                    </>
                                )}
                            </Content>
                        </>
                    )}
                </Content>
                <Footer style={{ textAlign: "center" }}>
                    {" "}
                    © ITEPREVENGO 2021
                </Footer>
            </Layout>
        </div>
    );
};

export default Course;



