import React from "react";
import {Row, Col, Card, Divider, Layout} from "antd";
import {Link} from "react-router-dom";
import {
    UserOutlined, CalendarFilled, DashboardOutlined, FileTextOutlined    
} from '@ant-design/icons';
import {useSelector} from "react-redux"

import "../../styles/seguriton.scss";

const { Content } = Layout;

const headStyleCard = {  color: '#f99400', borderRadius: '10px  10px 0 0', fontSize: '1.8rem' };

const Seguriton = ({seguriton, idProgramming, arrayExpert, rules, courses}) => {
    const responsive = useSelector(store => store.responsive.value)
    return <>
        <Content className="b-container">
            <Row style={{ width: '100%',marginTop:'65px' }} >
                <Col lg={24} xs={24} style={{ textAlign: 'center',marginTop:'65px' }}>
                    <h1 className="seguriton-title">{seguriton.name}</h1>
                    <Row >
                        <Col xs={24} style={{ textAlign: 'center' }}>

                            <img className='banner-seguriton' src={seguriton.banner}></img>
                            <br></br>
                            <br></br>

                        </Col>


                    </Row>

                </Col>

                <Col lg={24} xs={24} style={{ textAlign: 'center' }}>
                    {responsive?<p className='blue_iteprevengo seguriton-detail' >{seguriton.description}</p>:<h1 className="seguriton-detail">{seguriton.description}</h1>}
                    <br></br>
                    <Link to={"/inscription?id=" + idProgramming}>
                        <button style={{ fontSize: '28px', padding: '10px' }} className="btn-orange-iteprevengo"><b>Inscríbete aquí</b></button>
                    </Link>
                    <br></br>

                </Col>
            </Row>

            <Row style={{ width: '100%', marginTop: '20px', justifyContent: 'center' }} >
                {courses ? courses.map((d,index) => {
                    return <Col key={`card-seguriton-${index}`} xs={24} lg={10} style={{ margin: '10px' }}>
                                <div className="seguriton-course-title">
                                    {d.course_name}

                                </div>
                                    <Card className='card-info-seguriton-courses' >
                            
                                        <Row >
                                            {d.diaries && d.diaries.length>0 ?
                                                <>
                                                    <Col xs={24} style={{ textAlign: 'center' }}>
                                                    <h3 className="seguriton-detail-subtitle">Agenda</h3> 
                                                    </Col>
                                                    <Col xs={24}>
                                                        <div >
                                                            {d.diaries ? d.diaries.map((diary) => {

                                                                return <div style={{ margin: '10px' }}>

                                                                    <Card className="card-diary">
                                                                        <div className="card-diary-item"><CalendarFilled className="card-diary-icon" /> {diary.date}</div>
                                                                        <div className="card-diary-item"><DashboardOutlined className="card-diary-icon" /> {diary.hourInit} - {diary.hourEnd}</div>
                                                                        <div className="card-diary-item"><FileTextOutlined className="card-diary-icon" /> {diary.type}</div>
                                                                        <div className="card-diary-item"><UserOutlined className="card-diary-icon" /> {diary.expert_firm}</div>

                                                                    </Card>

                                                                </div>

                                                            }) : ''}
                                                        </div>

                                                    </Col>
                                                    <Col xs={24} style={{ textAlign: 'center' }}>
                                                        <Divider style={{ borderColor: '#072d70' }} dashed />
                                                    </Col>
                                                </>
                                            : null
                                            }
                                            
                                            <Col xs={24} style={{ textAlign: 'center' }}>
                                                <h3 className="seguriton-detail-subtitle">¿Qué aprenderé?</h3>
                                                <br></br>
                                                <div style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: d.course_learning }} />

                                            </Col>
                                            <Col xs={24} style={{ textAlign: 'center' }}>
                                                <Divider style={{ borderColor: '#072d70' }} />

                                            </Col>
                                            <Col xs={24} style={{ textAlign: 'center' }}>
                                                <p className='blue_iteprevengo'>Asesor: {d.expert.fullName}</p>

                                                <Link to={"/inscription?id=" + idProgramming}>
                                                    <button style={{ color: 'white !important' }} className="btn-orange-iteprevengo"><b>Inscríbete aquí</b></button>
                                                </Link>
                                            </Col>

                                        </Row>
                                    </Card>
                                </Col>
                }) : ''}


            </Row>
            <Divider />
            <Row style={{ width: '100%', marginTop: '20px', justifyContent: 'center' }} >
                <Col lg={{ span: 18 }}>
                    <Card className='card-info-course' title='¿Quiénes serán tus instructores?' headStyle={headStyleCard}>
                        <Row >

                            {arrayExpert ? arrayExpert.map((r) => {
                                return <>
                                    <Col xs={24} lg={10} style={{ textAlign: 'center', marginBottom: '10px', marginLeft: '8px' }}>
                                        <img className='photo-expert' src={r.photo}></img>

                                    </Col>
                                    <Col xs={24} lg={12} style={{ textAlign: 'center' }}>
                                        <p style={{ textAlign: 'center', fontSize: '20px', margin: '10px' }} className='blue_iteprevengo' >{r.firm}</p>
                                        <div style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: r.summary }} />
                                    </Col>
                                    <Col xs={24} style={{ textAlign: 'center' }}>
                                        <Divider style={{ borderColor: '#072d70' }} />
                                    </Col></>
                            }) : ''}

                        </Row>
                    </Card>
                </Col>

            </Row>

            <Row style={{ width: '100%', marginTop: '20px', justifyContent: 'center' }} >



                <Col xs={24} lg={10} style={{ margin: '10px' }}>
                    <Card className='card-info-course' title='¿Qué beneficios obtendrás?' headStyle={headStyleCard}>
                        <div style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: seguriton.benefits }} />

                    </Card>
                </Col>


                <Col xs={24} lg={10} style={{ margin: '10px'}} >
                    <Card className='card-info-course card-blue' >
                        <Row>
                            <Col xs={24} style={{ textAlign: 'center' }}>
                                <p>Precio por 1 CURSO:</p>

                                <p className='orange_iteprevengo' style={{ fontSize: '28px' }}>S/{courses.length>0 ? courses[0].price : ''}</p>
                            </Col>
                            <Col xs={24} style={{ textAlign: 'center' }}>
                                <Divider style={{ borderColor: '#fff', margin:'5px 0' }} />
                            </Col>
                            {rules ? rules.map((r) => {
                                return <> <Col xs={24} style={{ textAlign: 'center' }}>
                                    <p>Precio por {r.amount} CURSOS:</p>

                                    <p className='orange_iteprevengo' style={{ fontSize: '28px' }}>S/{r.pack}</p>
                                </Col>
                                    <Col xs={24} style={{ textAlign: 'center' }}>
                                        <Divider style={{ borderColor: '#fff', margin:'5px 0' }} />
                                    </Col></>
                            }) : ''}
                            <Col xs={24} style={{ textAlign: 'center' }}>
                                <h1 style={{ color: 'white', marginBottom:'0' }}>!VACANTES LIMITADAS!</h1>
                            </Col>
                        </Row>
                    </Card>
                </Col>


            </Row>
            <Row style={{ justifyContent: 'center' }}>

                <Col xs={24} lg={14} style={{ margin: '10px' }}>
                    <Card className='card-info-course' title='Condiciones del servicio' headStyle={headStyleCard}>
                        <div style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: seguriton.terms }} />

                    </Card>
                </Col>

                <Col xs={24} lg={14} style={{ margin: '10px' }}>
                    <Card className='card-info-course' title='Preguntas frecuentes' headStyle={headStyleCard}>
                        <div style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: seguriton.frequent_questions }} />

                    </Card>
                </Col>
                <Col xs={24} style={{ textAlign: 'center' }}>

                    <Link to={"/inscription?id=" + idProgramming}>
                        <button style={{ fontSize: '28px', padding: '10px' }} className="btn-orange-iteprevengo"><b>Inscríbete aquí</b></button>
                    </Link>

                </Col>
            </Row> 
        </Content>
        
    </>;
};

export default Seguriton;
