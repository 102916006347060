import React, { useState, useEffect } from "react";
import {
    Card,
    Form,
    Input,
    Button,
    Row,
    Col,
    Tag,
    message,
    Divider,
    Skeleton,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
    DownloadOutlined,
    MailOutlined,
    ShoppingCartOutlined,
    UserOutlined,
    HistoryOutlined,
    CalendarOutlined,
    CalendarFilled,
    DashboardOutlined,
    FileTextOutlined,
    ProfileOutlined,
    TrophyOutlined,
    TeamOutlined,
    StarFilled,
    CheckCircleOutlined,
    BookOutlined,
} from "@ant-design/icons";

import { useLocation, Link, Redirect } from "react-router-dom";
import { useHistory } from "react-router";

import axios from "../util/Api";
import star from "../assets/svg/star.ico";

import "../styles/kernel.scss";
import "../styles/course.scss";
import { setResponsive } from "../appRedux/actions/Responsive";
import NavBar from "../components/NavBarPublic";

import { Layout } from "antd";


import MetaTags from 'react-meta-tags';
import { Helmet } from "react-helmet";

const { Content, Footer } = Layout;

const Course = () => {
    const query = new URLSearchParams(useLocation().search);
    const id = query.get("token");
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const responsive = useSelector((store) => store.responsive.value);

    const [tabActive, setTabActive] = useState(0);
    let history = useHistory();

    const [data, setData] = useState([]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dispatch(setResponsive());
        getCertificate();
        console.log("responsive:", responsive);
    }, []);

    const getCertificate = () => {
        axios
            .post("/external/shareCertificate", { token: id }
            )
            .then(({ data, status }) => {
                if (status == 200) {
                    setData(data)
                    setLoading(true);
                    //  document.querySelector('meta[name="og:title"]').setAttribute("content", 'He completado el curso de: ' + + data.course_name);
                    //  document.querySelector('meta[name="og:image"]').setAttribute("content", data.img_certificate);

                    //  document.querySelector('meta[name="twitter:title"]').setAttribute("content", 'He completado el curso de: ' + + data.course_name);
                    //  document.querySelector('meta[name="twitter:image"]').setAttribute("content", data.img_certificate);

                } else {
                    window.location = 'https://www.iteprevengo.com/'
                }

            })
            .catch(function (error) {
                setLoading(true);
                console.log("Error****:", error.message);
            });
    };



    return (
        <div>
            <Helmet>
                <title>Curso completado exitosamente</title>
                <meta property="og:title" content='Curso completado' />
                <meta property="og:description" content={'He completado el curso de :' + data.course_name} />
                <meta property="og:image" content={data.img_certificate} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://cursosvirtuales.iteprevengo.com/" />
                {/* <meta property="fb:app_id" content="" /> */}


                <meta name="twitter:title" content='Curso completado' />
                <meta name="twitter:description" content={'He completado el curso de :' + data.course_name} />
                <meta name="twitter:image" content={data.img_certificate} />
            </Helmet>
            <Layout>
                <NavBar />
                <Content className="course-layout">


                    <Content className="course-presentation">
                        <Row
                            className="b-container wrap-presentation"
                            style={{
                                paddingTop: "1rem",
                                paddingBottom: "1rem",
                            }}
                        >
                            <Col
                                className="center-section"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={12}
                            >
                                {loading === false ? (
                                    <Skeleton.Image />
                                ) : (
                                        <div
                                            className="wrap-video"
                                            id=""
                                            style={{ textAlign: "left" }}
                                        >
                                            <img src={data.img_certificate} style={{ width: '100%' }} />
                                        </div>
                                    )}
                            </Col>
                            <Col xs={0} sm={0} md={0} lg={1}></Col>
                            <Col xs={24} sm={24} md={24} lg={11}>
                                {loading === false ? (
                                    <Skeleton
                                        paragraph={{ rows: 10 }}
                                        active
                                    />
                                ) : (
                                        <div style={{ padding: ".25rem" }}>
                                            <span className="label-category">
                                                {/* Categoría: {category} */}
                                            </span>
                                            <h1 className="title-course">
                                                {data.course_name}
                                            </h1>

                                            <ul className="list-features">
                                                <li>
                                                    <span>
                                                        <BookOutlined />{" "}
                                                        <Tag color="#f99400">
                                                            {data.modality}
                                                        </Tag>
                                                    </span>
                                                </li>


                                            </ul>
                                            <p className="subtitle-course">
                                                Esto confirma que <b>{data.fullName}</b>  ha completado un curso  en Iteprevengo:
                                           <b>{data.course_name}</b>
                                            </p>

                                            <p className="subtitle-course">
                                                <CheckCircleOutlined twoToneColor="#f99400" /> Encuentra más cursos sobre Seguridad y Salud.
                                            </p>

                                            <p className="subtitle-course">
                                                <button
                                                    onClick={() => window.location = 'https://www.iteprevengo.com/'}
                                                    className="btn-buy"
                                                >
                                                    <b>Ver más cursos</b>
                                                </button>
                                            </p>


                                        </div>
                                    )}
                            </Col>

                        </Row>
                    </Content>

                </Content>
                <Footer style={{ textAlign: "center" }}>
                    {" "}
                    © ITEPREVENGO 2021
                </Footer>
            </Layout>
        </div>
    );
};

export default Course;
