import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import Common from "./Common";
import Auth from "./Auth";
import Register from "./Register";
import Responsive from "./Responsive";



//import Settings from "./Settings";

export default (history) =>
    combineReducers({
        router: connectRouter(history),
        commonData: Common,
        auth: Auth,
        register: Register,
        responsive:Responsive
        //settings: Settings,
    });
