import logo from "./logo.svg";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import configureStore, { history } from "./appRedux/store";


import "../src/styles/kernel.scss";
import "antd/dist/antd.css";
import AppHome from "../src/containers/App/index";
require("chartkick")
require("chart.js")

const store = configureStore();

function App() {
    return (
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <Router>
                    <Switch>
                        <Route path="/" component={AppHome} />
                    </Switch>
                </Router>
            </ConnectedRouter>
        </Provider>
    );
}

export default App;
