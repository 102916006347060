
import React from "react";

import { Comment, Avatar, Input } from "antd";
import { DownloadOutlined, MailOutlined, ShoppingCartOutlined, UserOutlined, HistoryOutlined, CalendarOutlined, ProfileOutlined, TrophyOutlined, TeamOutlined, StarFilled, StarOutlined } from '@ant-design/icons';


const { TextArea } = Input;

const stars = (score) => {
    let stars = []
    for (let i = 0; i < score; i++) {
        stars.push(<StarFilled key={i} />)
    }

    for (let i = 0; i < 5-score; i++) {
        stars.push(<StarOutlined key={i} />)
    }

    return (<>{stars}</>)
}


const Module = ({ commentsList, children }) => {
    return (
        <>



            {commentsList
                ? commentsList.map((comment) => {

                    return (
                        <div style={{ textAlign: 'left' }}>


                            <Comment
                                actions={[<span key="comment-nested-reply-to"></span>]}
                                author={comment.user}
                                avatar={
                                    <Avatar
                                        src="/user.png"
                                        alt="Han Solo"
                                    />
                                }
                                content={
                                    <>
                                        <p style={{ fontSize: '10px', color: '#FA9400' }}>
                                            {stars(comment.score)}
                                        </p>
                                        <p>
                                            {comment.description}
                                        </p>
                                        <p style={{ fontSize: '10px', color: '#909290' }}>
                                            {comment.ago}
                                        </p>
                                    </>
                                }
                            >
                                {/* {children} */}
                                {comment.childs
                                    ? comment.childs.map((child) => {
                                        return (
                                            <div style={{ textAlign: 'left' }}>


                                                <Comment
                                                    actions={[<span key="comment-nested-reply-to"></span>]}
                                                    author={child.user}
                                                    avatar={
                                                        <Avatar
                                                            src="/man.png"
                                                            alt="Han Solo"
                                                        />
                                                    }
                                                    content={
                                                        <>
                                                            <p>
                                                                {child.description}
                                                            </p>
                                                            <p style={{ fontSize: '10px', color: '#909290' }}>
                                                                {child.registerDate}
                                                            </p>
                                                        </>
                                                    }
                                                >

                                                </Comment>

                                            </div>


                                        )

                                    })
                                    : ''}
                            </Comment>

                        </div>

                    )
                })
                : ''}


        </>
    );
};

export default Module;
