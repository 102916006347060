import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "../styles/welcome.scss"
import logo from "../imgs/logo_pacifico_small.png"

import calendar from "../imgs/calendar.png"
import evaluations from "../imgs/evaluations.png"
import recommendations from "../imgs/recommendations.png"
import { Row, Col, Divider } from 'antd';


import { MenuOutlined } from '@ant-design/icons';

const Welcome = (props) => {
    return(
        <div className="welcome-content">
            
            <h1>Welcome</h1>
            
        </div>
    );

}

export default Welcome;