import React, { useState, useEffect } from "react";
import { Card, Form, Input, Button, Row, Col, Select, Tag, message, Checkbox, Divider, Skeleton } from "antd";
import { useSelector, useDispatch } from 'react-redux'
import { TagFilled, UserOutlined, CalendarFilled, FileTextOutlined, DashboardOutlined, CreditCardFilled, CloudUploadOutlined, BankOutlined, TeamOutlined, StarFilled, FileDoneOutlined, CheckCircleOutlined, MailFilled, QuestionCircleOutlined } from '@ant-design/icons';
import { Layout, Radio } from "antd";

import { useLocation, Link } from "react-router-dom";

import axios from '../util/Api';

import '../styles/kernel.scss'
import '../styles/course.scss'
import { setResponsive } from "../appRedux/actions/Responsive";
import { getUser } from "../appRedux/actions/Auth";
import NavBar from "../components/NavBarPublic";


import yapeImg from '../assets/images/yape.jpeg';
import logoNiubiz from '../assets/images/logo-niubiz.png';
import logosCards from '../assets/images/BANNER CABECERA.png';


import checkedSVG from '../assets/svg/checked.svg';

const { Content, Footer } = Layout;


const Inscription = () => {
    const query = new URLSearchParams(useLocation().search)
    const id = query.get('id')
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const responsive = useSelector(store => store.responsive.value)
    const authUser = useSelector(store => store.auth.authUser)




    const [seguriton, setSeguriton] = useState([])
    const [expert, setExpert] = useState([])
    const [idProgramming, setIdProgramming] = useState([])




    let RegexEmail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,6})+$/;
    let RegexNumber = /^([0-9])*$/;




    useEffect(() => {



        dispatch(setResponsive())

        getSeguriton()



    }, [])


    useEffect(() => {

    }, [seguriton])




    const getSeguriton = () => {
        axios.get('/external/seguriton/' + id, {
            headers: {
                "X-Requested-With": "XMLHttpRequest"
            }
        }
        ).then(({ data }) => {

            setSeguriton(data.data)
            let a_expert = []
            let validate = true
            data.data.detail.map((d) => {
                a_expert.map((e) => {
                    if (e.idExpert == d.expert.idExpert) {
                        validate = false
                    }
                })
                if (validate) {
                    a_expert.push(d.expert)
                }
                setIdProgramming(d.idProgramming)
            })

            setExpert(a_expert)


        }).catch(function (error) {

            window.location = '/'
        })

    }



    return (
        <Layout>

            <NavBar />
            <Content className="site-layout" style={{backgroundColor:'#F5F5F4'}}>
                <Row style={{ width: '100%' }} >
                    <Col lg={24} xs={24} style={{ textAlign: 'center' }}>
                        <h1>{seguriton.name}</h1>
                        <Row >
                            <Col xs={24} style={{ textAlign: 'center' }}>

                                <img className='banner-seguriton' src={seguriton.banner}></img>
                                <br></br>
                                <br></br>

                            </Col>


                        </Row>

                    </Col>

                    <Col lg={24} xs={24} style={{ textAlign: 'center' }}>
                        {responsive?<p className='blue_iteprevengo' >{seguriton.description}</p>:<h1>{seguriton.description}</h1>}
                        <br></br>
                        <Link to={"/inscription?id=" + idProgramming}>
                            <button style={{ fontSize: '28px', padding: '10px' }} className="btn-orange-iteprevengo"><b>Inscríbete aquí</b></button>
                        </Link>
                        <br></br>

                    </Col>
                </Row>

                <Row style={{ width: '100%', marginTop: '20px', justifyContent: 'center' }} >
                    {seguriton.detail ? seguriton.detail.map((d) => {
                        return <Col xs={24} lg={10} style={{ margin: '10px' }}>
                                <div style={{ fontSize:'16px',backgroundColor: '#072d70', color: 'white', borderRadius: '10px  10px 0 0',padding:'10px' }  }>
                         {d.course_name}

                         </div>
                            <Card className='card-info-seguriton-courses' >
                     
                                <Row >

                                    <Col xs={24}>
                                        <Row style={{ justifyContent: 'center' }}>
                                            {d.diaries ? d.diaries.map((diary) => {

                                                return <Col xs={17} lg={10} style={{ margin: '10px' }}>

                                                    <Card bodyStyle={{ backgroundColor: '#072d70', color: 'white', borderRadius: '10px', textAlign: 'left' }}>
                                                        <p><CalendarFilled /> {diary.date}</p>
                                                        <p><DashboardOutlined /> {diary.hourInit} - {diary.hourEnd}</p>
                                                        <p><FileTextOutlined /> {diary.type}</p>
                                                        <p><UserOutlined /> {diary.expert_firm}</p>

                                                    </Card>

                                                </Col>

                                            }) : ''}
                                        </Row>

                                    </Col>
                                    <Col xs={24} style={{ textAlign: 'center' }}>
                                        <Divider style={{ borderColor: '#072d70' }} dashed />
                                    </Col>
                                    <Col xs={24} style={{ textAlign: 'center' }}>
                                        <h3>¿Qué aprenderé?</h3>
                                        <br></br>
                                        <div style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: d.course_learning }} />

                                    </Col>
                                    <Col xs={24} style={{ textAlign: 'center' }}>
                                        <Divider style={{ borderColor: '#072d70' }} />

                                    </Col>
                                    <Col xs={24} style={{ textAlign: 'center' }}>
                                        <p className='blue_iteprevengo'>Asesor: {d.expert.fullName}</p>

                                        <Link to={"/inscription?id=" + idProgramming}>
                                            <button style={{ color: 'white !important' }} className="btn-orange-iteprevengo"><b>Inscríbete aquí</b></button>
                                        </Link>
                                    </Col>

                                </Row>
                            </Card>
                        </Col>
                    }) : ''}


                </Row>
                <Divider />
                <Row style={{ width: '100%', marginTop: '20px', justifyContent: 'center' }} >
                    <Card className='card-info-course' title='¿Quiénes serán tus instructores?' headStyle={{ backgroundColor: '#072d70', color: '#f99400', borderRadius: '10px  10px 0 0', fontSize: '22px' }}>
                        <Row>

                            {expert ? expert.map((r) => {
                                return <>
                                    <Col xs={24} lg={10} style={{ textAlign: 'center', marginBottom: '10px', marginLeft: '8px' }}>
                                        <img className='photo-expert' src={r.photo}></img>
                                        {/* <img className='photo-expert' src='https://infocursos.pymhse.com/static/media/giacarlo-arditto.2225e90b.jpg'></img> */}

                                    </Col>
                                    <Col xs={24} lg={12} style={{ textAlign: 'center' }}>
                                        <p style={{ textAlign: 'center', fontSize: '20px', margin: '10px' }} className='blue_iteprevengo' >{r.firm}</p>
                                        <div style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: r.summary }} />
                                    </Col>
                                    <Col xs={24} style={{ textAlign: 'center' }}>
                                        <Divider style={{ borderColor: '#072d70' }} />
                                    </Col></>
                            }) : ''}

                        </Row>
                    </Card>


                </Row>

                <Row style={{ width: '100%', marginTop: '20px', justifyContent: 'center' }} >



                    <Col xs={24} lg={10} style={{ margin: '10px' }}>
                        <Card className='card-info-course' title='¿Qué beneficios obtendrás?' headStyle={{ fontSize: '25px', textAlign: 'center', color: '#ea8d05', borderRadius: '10px  10px 0 0' }}>
                            <div style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: seguriton.benefits }} />

                        </Card>
                    </Col>


                    <Col xs={24} lg={10} style={{ margin: '10px' }}>
                        <Card className='card-info-course' bodyStyle={{ backgroundColor: '#072d70', fontSize: '20px', textAlign: 'center', color: '#fff', borderRadius: '10px  10px 10px 10px' }}>
                            <Row>
                                <Col xs={24} style={{ textAlign: 'center' }}>
                                    <p>Precio por 1 CURSO:</p>

                                    <p className='orange_iteprevengo' style={{ fontSize: '70px' }}>S/{seguriton.detail ? seguriton.detail[0].price : ''}</p>
                                </Col>
                                <Col xs={24} style={{ textAlign: 'center' }}>
                                    <Divider style={{ borderColor: '#fff' }} />
                                </Col>
                                {seguriton.rules ? seguriton.rules.map((r) => {
                                    return <> <Col xs={24} style={{ textAlign: 'center' }}>
                                        <p>Precio por {r.amount} CURSOS:</p>

                                        <p className='orange_iteprevengo' style={{ fontSize: '70px' }}>S/{r.discount}</p>
                                    </Col>
                                        <Col xs={24} style={{ textAlign: 'center' }}>
                                            <Divider style={{ borderColor: '#fff' }} />
                                        </Col></>
                                }) : ''}
                                <Col xs={24} style={{ textAlign: 'center' }}>
                                    <h1 style={{ color: 'white' }}>!VACANTES LIMITADAS!</h1>
                                </Col>
                            </Row>
                        </Card>
                    </Col>


                </Row>
                <Row style={{ justifyContent: 'center' }}>

                    <Col xs={24} lg={14} style={{ margin: '10px' }}>
                        <Card className='card-info-course' title='Condiciones del servicio' headStyle={{ fontSize: '25px', textAlign: 'center', color: '#ea8d05', borderRadius: '10px  10px 0 0' }}>
                            <div style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: seguriton.terms }} />

                        </Card>
                    </Col>

                    <Col xs={24} lg={14} style={{ margin: '10px' }}>
                        <Card className='card-info-course' title='Preguntas frecuentes' headStyle={{ fontSize: '25px', textAlign: 'center', color: '#ea8d05', borderRadius: '10px  10px 0 0' }}>
                            <div style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: seguriton.frequent_questions }} />

                        </Card>
                    </Col>
                    <Col xs={24} style={{ textAlign: 'center' }}>

                        <Link to={"/inscription?id=" + idProgramming}>
                            <button style={{ fontSize: '28px', padding: '10px' }} className="btn-orange-iteprevengo"><b>Inscríbete aquí</b></button>
                        </Link>

                    </Col>
                </Row>




            </Content>
            <Footer style={{ textAlign: 'center' }}>© ITEPREVENGO 2021</Footer>
        </Layout >
    );
};


export default Inscription;