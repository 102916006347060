import React from "react";
import {} from "antd";
import {
    CalendarFilled,
    DashboardOutlined,
    FileTextOutlined,
    UserOutlined,
    EnvironmentOutlined,
} from "@ant-design/icons";

import "../../styles/kernel.scss";

const DiaryItem = ({ diary, number }) => {
    return (
        <>
            <div className="card-diary">
                <div className="card-diary-number">Agenda {number}</div>
                <div className="card-diary-detail">
                    <CalendarFilled /> {diary.date}
                </div>
                <div className="card-diary-detail">
                    <DashboardOutlined /> {diary.hourInit} - {diary.hourEnd}
                </div>
                <div className="card-diary-detail">
                    <FileTextOutlined /> {diary.type}
                </div>
                <div className="card-diary-detail">
                    <UserOutlined /> {diary.expert}
                </div>
                <div className="card-diary-detail">
                    <EnvironmentOutlined /> {diary.auditorium}
                </div>
            </div>
        </>
    );
};

export default DiaryItem;
