import React, { useEffect, useState } from "react"
import "../styles/login.scss"
import { Form, Input } from "antd"
import { useDispatch, useSelector } from "react-redux"
import InfoView from "../components/InfoView"
import { Link } from "react-router-dom"
import logo from "../imgs/logo_academy.png"

import { userSignIn } from "../appRedux/actions/Auth"
import axios from "../util/Api"
import "../styles/sliders.scss"

const Login = (props) => {
  const dispatch = useDispatch()
  const token = useSelector(({ auth }) => auth.token)
  const [tokenRecaptcha, setTokenRecaptcha] = useState("")
  const [showRecaptcha, setShowRecaptcha] = useState(false)

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo)
  }

  const onFinish = (values) => {
    //console.log("finish", values);
    if (showRecaptcha) {
      handleLoaded()

      if (tokenRecaptcha != "") {
        values.token = tokenRecaptcha

        dispatch(userSignIn(values))
      }
    } else {
      dispatch(userSignIn(values))
    }
  }

  const recaptcha = () => {
    axios
      .get("external/recaptcha", {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
      })
      .then(({ data }) => {
        setShowRecaptcha(data.message)
      })
      .catch(function (error) {
        setShowRecaptcha(false)
      })
  }

  useEffect(() => {
    if (token !== null) {
      var obj = document.getElementById("script")
      if (obj) {
        obj.remove()
      }
      window.location = "/home"
    }
  }, [token, props.history])

  const handleLoaded = (_) => {
    window.grecaptcha.ready((_) => {
      window.grecaptcha
        .execute("6Led0P0UAAAAAEyyrKlkd3zrZT3GnimnbOznbGCY", {
          action: "homepage",
        })
        .then((token) => {
          setTokenRecaptcha(token)
        })
    })
  }

  useEffect(() => {
    if (showRecaptcha) {
      const script = document.createElement("script")
      script.id = "script"
      script.src =
        "https://www.google.com/recaptcha/api.js?render=6Led0P0UAAAAAEyyrKlkd3zrZT3GnimnbOznbGCY"
      script.addEventListener("load", handleLoaded)
      document.body.appendChild(script)
    }
  }, [showRecaptcha])

  useEffect(() => {
    recaptcha()
  }, [])

  return (
    <>
      <ul className="cb-slideshow">
        <li>
          <span>Image 01</span>
        </li>
        <li>
          <span>Image 02</span>
        </li>
        <li>
          <span>Image 03</span>
        </li>
        <li>
          <span>Image 04</span>
        </li>
        <li>
          <span>Image 05</span>
        </li>
        <li>
          <span>Image 06</span>
        </li>
      </ul>
      <div className="body login-content">
        <script src="https://www.google.com/recaptcha/api.js?render=6Led0P0UAAAAAEyyrKlkd3zrZT3GnimnbOznbGCY"></script>
        <div className="img-logo">
          <Link to="/login">
            <img className="logo-ini" src={logo} />
          </Link>
        </div>

        <div className="card-login">
          <Form
            initialValues={{ remember: true }}
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className="gx-signin-form gx-form-row0"
          >
            <Form.Item
              rules={[
                { required: true, message: "Usuario requerido" },
                { max: 100, message: "Solo se permiten maximo 100 caracteres" },
              ]}
              name="email"
            >
              <Input
                placeholder="Usuario"
                className="password_input"
                allowClear
              />
            </Form.Item>
            <Form.Item
              rules={[
                { required: true, message: "Contraseña requerida" },
                { max: 100, message: "Solo se permiten maximo 100 caracteres" },
              ]}
              name="password"
            >
              <Input.Password
                className="password_input"
                placeholder="Contraseña"
              />
            </Form.Item>
            <Form.Item style={{ textAlign: "center", margin: 0 }}>
              <Link to="/forgot-password" className="grey-link">
                ¿Olvidaste tu contraseña?
              </Link>
              <div className="d-flex justifyContentCenter">
                <button className="btn-orange" type="submit">
                  Iniciar Sesión {showRecaptcha}
                </button>
              </div>

              <a className="primary-link" href="/register">
                Registrarse
              </a>
            </Form.Item>
          </Form>
        </div>
        <InfoView />
        <div
          className="g-recaptcha"
          data-sitekey="6Led0P0UAAAAAEyyrKlkd3zrZT3GnimnbOznbGCY"
          data-size="invisible"
        ></div>
      </div>
    </>
  )
}

export default Login
